.LandingPageMain{
    border:1px solid black;
}

.ball {
    margin-top: 75px;
    background-color: cornflowerblue;
    border: 2px solid #999;
    animation: bounce 0.5s infinite alternate;
    -webkit-animation: bounce 0.5s infinite alternate;
  }
  @keyframes bounce {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(-3.5px);
    }
  }
  @-webkit-keyframes bounce {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(-3.5px);
    }
  }


  .MuiMobileStepper-dot {
    background-color: white !important;
    margin:0 8px !important
}

  .MuiMobileStepper-dotActive {
    background-color: black !important;
    border-radius: 100%;
    border: white 2px solid;
    box-sizing: border-box;
}
.leaflet-container {
  width: 100%;
  height: 40vh;
}